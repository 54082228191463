import * as React from "react";
import { Helmet } from "react-helmet";
import PageWithBackgroundImage from "../components/pageWithBackgroundImage";

// markup
const IndexPage = ({ location }) => {
  return (
    <PageWithBackgroundImage location={location}>
      <Helmet>
        <Helmet>
          <title>Portal clienti - Design-Proiect</title>
          <meta name="robots" content="noindex" />
        </Helmet>
      </Helmet>
    </PageWithBackgroundImage>
  );
};

export default IndexPage;
